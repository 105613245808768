<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div>
        <h6 class="title-h6 q-mt-none">{{$t('processes.linked_entity_or_pdv_wallets')}}</h6>
        <div class="row q-col-gutter-md q-mb-md">
          <linked-object-list v-if="linkedObjects.length" :linkedObjects="linkedObjects" @menuClick="onMenuClick($event)" class="col" />
          <!-- Search div -->
          <search-box v-if="searchBoxActivated" ref="searchBox"
            class="col-5 full-height"
            @input="onSelectLinkedObj"
            :label="$t('processes.fields.model.label')"
            :options="[
              { value: 'entity', label: 'Entité' },
              { value: 'wallet', label: 'Portefeuille' },
            ]"
            :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true, 'pdv': 1 }"
            :color="color" />
          <q-btn icon="add_circle" v-else :color="color" class="add-pdv-entity q-mt-md q-ml-md col-5" size="md" outline @click="searchBoxActivated = true">
            {{$t('processes.link_entity_or_pdv_wallet')}}
          </q-btn>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row">
              <div class="col-6">
                <base-input-select class="col" v-bind="formInputProps('origine_du_besoin')" :color="color"
                  v-model="formData.origine_du_besoin" />
              </div>
            </div>
          </q-card-section>
        </q-card>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_effet')" :color="color"
                v-model="formData.date_effet" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_envoi_du_formulaire_au_prestataire')" :color="color"
                v-model="formData.date_envoi_du_formulaire_au_prestataire" ref="label" />
            </div>
          </q-card-section>
        </q-card>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_reception_du_survey')" :color="color"
                v-model="formData.date_reception_du_survey" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_validation_du_survey_par_agent')" :color="color"
                v-model="formData.date_validation_du_survey_par_agent" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_demande_autorisation_mairie')" :color="color"
                v-model="formData.date_demande_autorisation_mairie" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_autorisation_mairie')" :color="color"
                v-model="formData.date_autorisation_mairie" ref="label" />
            </div>
          </q-card-section>
        </q-card>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_mise_en_fabrication_par_le_prestataire')" :color="color"
                v-model="formData.date_mise_en_fabrication_par_le_prestataire" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_de_pose')" :color="color"
                v-model="formData.date_de_pose" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_reception')" :color="color"
                v-model="formData.date_reception" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_reception_facture')" :color="color"
                v-model="formData.date_reception_facture" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-text class="col" v-bind="formInputProps('montant_de_la_facture_part_GAN')" :color="color"
                v-model="formData.montant_de_la_facture_part_GAN" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_reglement_part_GAN')" :color="color"
                v-model="formData.date_reglement_part_GAN" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-text class="col-6" v-bind="formInputProps('montant_facture_part_agent')" :color="color"
                v-model="formData.montant_facture_part_agent" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item">
        <h6>{{$t('processes.links')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="row q-gutter-md collapsible-div">
          <div v-if="relatedLinks.length" class="column col col-6 q-py-md q-col-gutter-md">
            <div class="row q-gutter-sm" v-for="relatedLink in relatedLinks" :key="relatedLink.id">
              <base-card :showSeparator="false" @menuClick="onMenuClick" class="col-12" :actions="relatedLink.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
                <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
                <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
              </base-card>
            </div>
          </div>

          <div v-if="!isLinksFormOpen" class="col-6" style="height:81px">
            <base-add-button :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
          </div>

          <div v-else class="col-6">
            <q-card flat bordered class="q-pa-md">
              <base-input-text v-bind="titleTemp" :color="color"
                  v-model="link.titre" ref="admin_note" />
              <base-input-text v-bind="linkTemp" :color="color"
                  v-model="link.lien" ref="admin_note" />
              <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink()">{{$t('form.ok')}}</q-btn>
              <div style="clear: both;"></div>
            </q-card>
          </div>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import linkedObjectList from '../shared/linkedObjectList'

export default {
  mixins: [BaseForm],
  components: { linkedObjectList },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      searchBoxActivated: false,
      isUpdate: this.$route.params.id,
      linkedObjects: [],
      booleanOption: [
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
      ],

      // For links
      relatedLinks: [],
      isLinksFormOpen: false,
      titleTemp: { label: 'Titre du lien' },
      linkTemp: { label: 'Chemin réseau' },
      link: {
        titre: '',
        lien: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentSuiviDeDossierSignaletique: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    })
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'signaletique'
        this.formData.related_objects = []
        if (!newVal || !this.currentSuiviDeDossierSignaletique) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options, type, boolean }) => ({ id, options, type, boolean }))
            .reduce((acc, { id, options, type, boolean }) => ({
              ...acc,
              [id]: options
                ? options.find(({ value }) => value === this.currentSuiviDeDossierSignaletique[id])
                : type === 'select' && boolean
                  ? this.booleanOption.find(val => val.value === this.currentSuiviDeDossierSignaletique[id])
                  : this.currentSuiviDeDossierSignaletique[id]
            }), {})
        }

        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id
          this.linkedObjects = []

          // Set wallets data
          let walletsMapped = this.currentSuiviDeDossierSignaletique.wallets.map(wallet => ({
            model_type: 'wallet',
            model_id: wallet.id
          }))
          this.formData.related_objects.push(...walletsMapped)

          let mappedObjectForLinkedObjectsWallets = this.currentSuiviDeDossierSignaletique.wallets.map(wallet => ({
            type: 'wallet',
            model: {
              label: wallet.NOMPTF,
              ...wallet,
              actions: [
                { label: this.$t('processes.unlink_related_object'), payload: { name: 'model-detach', params: { id: wallet.id, type: 'wallet' } } }
              ]
            }
          }))

          this.linkedObjects.push(...mappedObjectForLinkedObjectsWallets)

          // Set entities data
          let entitiesMapped = this.currentSuiviDeDossierSignaletique.entities.map(entity => ({
            model_type: 'entities',
            model_id: entity.id
          }))
          this.formData.related_objects.push(...entitiesMapped)

          let mappedObjectForLinkedEntities = this.currentSuiviDeDossierSignaletique.entities.map(entity => ({
            type: 'entity',
            model: {
              ...entity,
              actions: [
                { label: this.$t('processes.unlink_related_object'), payload: { name: 'model-detach', params: { id: entity.id, type: 'entity' } } }
              ]
            }
          }))

          this.linkedObjects.push(...mappedObjectForLinkedEntities)

          // Set links
          this.relatedLinks = this.currentSuiviDeDossierSignaletique.liens ? JSON.parse(this.currentSuiviDeDossierSignaletique.liens).map(relatedLink => {
            return {
              ...relatedLink,
              actions: [
                { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
              ]
            }
          }) : []
          this.formFields.find(form => form.id === 'liens').type = 'text'
        }
      },
      immediate: true
    },
    relatedLinks (newVal, oldVal) {
      this.formData.liens = newVal.length > 0 ? newVal.filter(lien => lien.id !== null).map(({ titre, lien, id }) => ({ titre, lien, id })) : []
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_de_dossier_signaletique'), to: { name: 'suivi-de-dossier-signaletique-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-de-dossier-signaletique-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_de_dossier_signaletique'))

    this.$store.commit('pages/setTabs', [
      { name: 'suivi-de-dossier-signaletique-form', label: 'Informations', to: { name: 'suivi-de-dossier-signaletique-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'signaletique' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'signaletique')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'signaletique')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    addLink () {
      if (this.link.titre === '' || this.link.lien === '') return
      let tempId = 0
      if (!this.relatedLinks) {
        this.relatedLinks = []
      } else if (this.relatedLinks.length) {
        tempId = Math.max.apply(Math, this.relatedLinks.map(link => link.id)) + 1
      }

      // Remove " first before adding
      let copiedObject = Object.assign({}, this.link)
      copiedObject.id = tempId
      copiedObject.lien = copiedObject.lien.replace(/"/g, '')
      copiedObject.actions = [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'delete-link', params: { id: tempId } } }]

      this.relatedLinks.push(copiedObject)
      this.link.titre = ''
      this.link.lien = ''
      this.isLinksFormOpen = false
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    onSelectLinkedObj (object) {
      if (!object.value) return

      let getModel_typeFromSearch = (type) => type === 'entity' ? 'entities' : type
      let mappedObjectForFormData = {
        model_type: getModel_typeFromSearch(object.type),
        model_id: object.value.value
      }

      let mappedObjectForLinkedObjects = {
        type: object.type,
        model: {
          label: object.value.label,
          id: object.value.value,
          actions: [
            { label: this.$t('processes.unlink_related_object'), payload: { name: 'model-detach', params: { id: object.value.value, type: object.type } } }
          ]
        }
      }

      this.linkedObjects.push(mappedObjectForLinkedObjects)
      this.formData.related_objects.push(mappedObjectForFormData)

      this.$refs.searchBox.reset()
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-de-dossier-signaletique-form', params: { id } })
    },
    copyCour () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    updateWallet (wallets) {
      console.log(wallets)
      let walletMapped = wallets.map(wallet => ({
        model_type: 'wallet',
        model_id: wallet.id
      }))

      if (!this.formData.related_objects) this.formData.related_objects = []
      // Remove all wallet in related object
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'wallet')
      this.formData.related_objects.push(...walletMapped)
    },
    onMenuClick ({ name, params }) {
      if (name === 'model-detach') {
        let linkedWithTypeEqualsParamsTypeIdRemoved = this.linkedObjects.filter(linkObj => linkObj.type === params.type && linkObj.model.id !== params.id)
        let linkedWithTypeDifferentsParamsType = this.linkedObjects.filter(linkObj => linkObj.type !== params.type)
        this.linkedObjects = [...linkedWithTypeEqualsParamsTypeIdRemoved, ...linkedWithTypeDifferentsParamsType]

        let paramsTypeMapped = params.type === 'entity' ? 'entities' : params.type
        let relatedObjectWithTypeEqualsParamsTypeIdRemoved = this.formData.related_objects.filter(relObj => relObj.model_type === paramsTypeMapped && relObj.model_id !== params.id)
        let relatedObjectWithTypeDifferentsParamsType = this.formData.related_objects.filter(relObj => relObj.model_type !== paramsTypeMapped)
        this.formData.related_objects = [...relatedObjectWithTypeEqualsParamsTypeIdRemoved, ...relatedObjectWithTypeDifferentsParamsType]
      } else if (name === 'delete-link') {
        this.relatedLinks = this.relatedLinks.filter(link => link.id !== params.id)
      }
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'signaletique', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .process-color
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
  .add-pdv-entity
    height 228px
</style>
